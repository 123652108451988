import {Route, Routes, useLocation} from 'react-router-dom'
import Home from './Pages/Home'
import ButterflyAbout from './Pages/ButterflyAbout'


const AppRoutes = () => {

    const location = useLocation()

    return (
        <Routes location={location} key={location.pathname}>
            <Route path="/*" element={<Home />} />
            <Route path="/butterfly/about" element={ <ButterflyAbout/> }/>
        </Routes>
    )
}

export default AppRoutes