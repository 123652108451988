import Sied from "../../Assets/Sied.svg"
import SiedBackground from "../../Assets/SiedBackground.svg"
import SiedWords0 from "../../Assets/SiedWords0.svg"
import SiedWords1 from "../../Assets/SiedWords1.svg"

const Home = ()=> {

   var flip = 2;

    const siedFlip = () => {

        document.querySelector('.entranceLine').classList.toggle('borderSwitch');

        document.querySelector('.sied').classList.toggle('hovered');

        if ((flip%2) === 0) {

            document.querySelector('.entranceWords0').classList.toggle('rotateInvisible');
            setTimeout(()=> {document.querySelector('.entranceWords0').classList.toggle('noDisplay');}, 1000);

            setTimeout(()=> {document.querySelector('.entranceWords1').classList.toggle('Display');}, 1000);

            //setTimeout(()=> {document.querySelector('.entranceWords1').classList.toggle('rotateVisible');}, 1300);

            setTimeout(()=> {document.querySelector('.console input').classList.toggle('inVisible');}, 2000);

            setTimeout(()=> {document.querySelector('.console input').focus();}, 2300);

        }
        else {
            
            //document.querySelector('.entranceWords1').classList.toggle('rotateVisible');
            document.querySelector('.console input').classList.toggle('inVisible');

            //setTimeout(()=> {document.querySelector('.entranceWords1').classList.toggle('Display');}, 1000);

            setTimeout(()=> {document.querySelector('.entranceWords0').classList.toggle('noDisplay');}, 1000);

            setTimeout(()=> {document.querySelector('.entranceWords0').classList.toggle('rotateInvisible');}, 1300);

        }

        flip += 1;

    };

  return (
    <div className="sied-container">
      <div className="sied-container-images">
        <img src={Sied} className="sied hover" onClick={()=>siedFlip()} />
        <img src={SiedBackground} className="entranceBackground" />
      </div>
            <div className="sied-container-body">
                <div className="console">
                    <form>
                        <p><input type="text" name="console" className="inVisible" placeholder="A Razed World | Our Vestigial Hope" /></p>
                    </form>
                  <div className="entranceLine"></div>
                  <img src={SiedWords0} class="entranceWords0" />
                  <img src={SiedWords1} className="entranceWords1" />
                </div>
            </div>
    </div>
  );
}

export default Home;
