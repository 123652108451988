import React, { Component } from 'react';

class LogoSparkles extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

  }

  render() {

    return (
      <React.Fragment>
        <div className="logoDotContainer">
          <span className={"logoDot logoDotLowerLeft"}/>
          <span className={"logoDot logoDotLowerLeft"}/>
          <span className={"logoDot logoDotLowerLeftReflect"}/>
          <span className={"logoDot logoDotLowerLeftReflect2"}/>
        </div>
        <div className="logoDotContainerRight">
          <span className={"logoDot logoDotLowerLeft"}/>
          <span className={"logoDot logoDotLowerLeft"}/>
          <span className={"logoDot logoDotLowerLeftReflect"}/>
          <span className={"logoDot logoDotLowerLeftReflect2"}/>
        </div>
        <div className="logoDotContainerTop ">
          <span className={"logoDot logoDotTopLeft"}/>
          <span className={"logoDot logoDotTopLeftReflect"}/>
        </div>
        <div className="logoDotContainerTopLeft ">
          <span className={"logoDot logoDotTopLeft"}/>
          <span className={"logoDot logoDotTopLeftReflect"}/>
        </div>
      </React.Fragment>
    );
  }
}

export default LogoSparkles;
