import Logo from "../../Components/logo/LogoSparkles"
import spotifyBanner from "../../Assets/Banner-Spotify.png"
import logo from "../../Assets/logoSquare.png"
import logoBanner from "../../Assets/Butterfly Logo.png"
import siedLogo from "../../Assets/siedSquareNoBackground.png"
import logoInsta from "../../Assets/insta.png"
import logoTiktok from "../../Assets/tiktok.png"
import album from "../../Assets/Calamity-Album-Art-Front.png"
import ally from "../../Assets/ally1.png"
import atlas from "../../Assets/MemberPhotos/atlas.png"
import demo0 from "../../Assets/DemoPhotos/demo0.jpg"
import demo1 from "../../Assets/DemoPhotos/demo1.jpg"
import demo2 from "../../Assets/DemoPhotos/demo2.jpg"
import demo3 from "../../Assets/DemoPhotos/demo3.jpg"

function ButterflyAbout() {
  return (
    <>
      <section className="nav">
        <a href="/">
          <img src={logo} className="logoSmall logoButterfly" />
        </a>
      </section>
      <section className="row center">
        <Logo />
        <img src={logoBanner} className="full movement" />
      </section>
      <section className="row center">
        <img src={spotifyBanner} className="full darken headerPic" />
        <div className="container half absolute infoCard fullHeight cardDark firstCard">
          <p>Butterfly is the story of an amnesiac trapped in the internet, finding friends and a desire to destroy the world. Every song released is part of this story.</p>
          <a href="https://www.youtube.com/watch?v=HXolq0nehIw&list=PLplo2neWSG3U-Mb5w5jUnTwPE2WNk0fLf&index=1" className="button">Watch The Story</a>
        </div>
      </section>
      <h1 className="headerText">Calamity</h1>
      <section className="row">
          <img src={album} className="half movement0 album" />
          <div className="container half infoCard textRight">
            <p>Calamity is the short and destructive debut album from Butterfly. Using Glitchy dynamics and laser-like guitar sounds to bring destruction to a broken world. <br/> Released January 31st, 2022</p>
            <div className="row full spaceAround containerButtons">
              <a href="https://open.spotify.com/track/6as7K19hrthbgezKjBc9m1?si=8d0a97f3b06d4e32" className="button">Spotify</a>
              <a href="https://www.youtube.com/watch?v=khhqJSNFwvI&list=PLplo2neWSG3XNdTfYorGaXXgTF4kM3gYq&index=2&t=0s" className="button">Youtube</a>
              <a href="https://butterflymusic.bandcamp.com/album/calamity" className="button">Bandcamp</a>
            </div>
          </div>
      </section>
      <h1 className="headerText">Members</h1>
      <section className="row">
        <div className="column half">
          <div className="row full">
            <img src={ally} className="half" />
            <div className="profileColumn half whiteBG">
              <p><span className="bold">Name</span> ???</p>
              <p><span className="bold">Age</span> ???</p>
            </div>
          </div>
          <div className="profileColumn">
            <p><span className="bold">Skills</span> Art & Crying</p>
            <p><span className="bold">Info</span> <span className="valueText">An Amnesiac who woke up trapped in a white room in the internet. Determined to defeat the all consuming loneliness, they tell the story of Butterfly</span></p>
          </div>
        </div>
        <div className="column half">
          <div className="row full">
            <img src={atlas} className="half"/>
            <div className="profileColumn half whiteBG">
              <p><span className="bold">Name</span> <span className="valueText">Atlas Helicopter</span></p>
              <p><span className="bold">Age</span> 652</p>
            </div>
          </div>
          <div className="profileColumn">
            <p><span className="bold">Skills</span> <span className="valueText">Quantum-Navigation & Drumming</span></p>
            <p><span className="bold">Info</span> <span className="valueText">A member of the Butterfly Unit of Sied. he became great friends with ??? while investigating a rare Media signal in a hidden universe</span></p>
          </div>
        </div>
      </section>
      <h1 className="headerText">Butterfly Game</h1>
      <section className="center cardsRow">
        <div className="frame quarter">
          <img src={demo0} />
        </div>
        <div className="frame quarter">
          <img src={demo1} />
        </div>
        <div className="frame quarter">
          <img src={demo2} />
        </div>
      </section>
      <section className="center full">
        <div className="container half infoCard marginAuto">
          <p className="textSmaller">Butterfly Game puts you into the story of Butterfly. Become friends with the amnesiac, battle-box for worthless memoryballs, and have fun! <br/> Release for Android in May 2022</p>
          <a href="https://butterflyscreen.com/" className="button">Play The Demo On Mobile</a>
        </div>
      </section>
      <section className="footer">
        <a href="https://www.instagram.com/butterflytheband/">
          <img src={logoInsta} className="logoSmaller logoInsta" />
        </a>
        <a href="/">
          <img src={siedLogo} className="logoSmall" />
        </a>
        <a href="https://vm.tiktok.com/TTPdkCmSog/">
          <img src={logoTiktok} className="logoSmaller" />
        </a>
      </section>
    </>
  );
}

export default ButterflyAbout;
